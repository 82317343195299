import React, { useContext } from 'react';
import { Row } from 'react-bootstrap';
import LanguageContext from '../utils/LanguageContext';
import translations from '../utils/translation';
import { Link } from 'react-router-dom';
import Footer from '../components/common/Footer';

const ActivityOver = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];

  return (
    <>
      <div className="pm_container">
        <div className="content">
          <img
            src="assets/images/activity-ended-logo.png"
            id="thumsup-image"
            alt={''}
          />
          <p>
            This promotion has now ended.
            <br /> You can continue to view the winner <br /> list here.
          </p>
          <Link to={'/winnerList'}>
            <button className="btn btn-sm btn-primary">WINNER LIST</button>
          </Link>
        </div>
        {/* <img
          src="assets/images/player-left-img.png"
          id="player-left"
          alt={''}
        />
        <img
          src="assets/images/player-right-img-flip.png"
          id="player-right"
          alt={''}
        /> */}
      </div>
    </>
  );
};

export default ActivityOver;
