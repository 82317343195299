import React, { useState, useEffect, useContext } from 'react';
import {
  getMerchList,
  getWinTicketList,
  getTicketWinnerCount,
  getSilverCoinList,
} from '../../actions/Auth';
import { LoaderContext } from '../loader/LoaderContext';
import Loader from '../loader';
import LanguageContext from '../../utils/LanguageContext';
import translations from '../../utils/translation';
import { getUserDetails } from '../../utils/helper';

const NewWinnerTable = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];
  const { toggleLoader } = useContext(LoaderContext);
  const [ticketActive, setTicketActive] = useState(true);
  const [activetab, setActiveTab] = useState('ticket');
  const [ticketList, setTicketList] = useState([]);
  const [merchList, setMerchList] = useState([]);
  const [silverCoinList, setCoinList] = useState([]);
  const [numberOfRecordsShow, setNumberOfRecordsShow] = useState(
    window.innerWidth < 768 ? 5 : 10
  );
  const [ticketsApiPage, setTicketApiPage] = useState(1);
  const [merchApiPage, setMerchApiPage] = useState(1);
  const [coinApiPage, setCoinApiPage] = useState(1);
  const [totalTicketDetails, setTotalTicketDetails] = useState('');
  const [totalMerchDetails, setTotalMerchDetails] = useState('');
  const [totalCoinDetails, setTotalCoinDetails] = useState('');
  const [ticketPageNumber, setTicketPageNumber] = useState(0);
  const [merchPageNumber, setMerchPageNumber] = useState(0);
  const [coinPageNumber, setCoinPageNumber] = useState(0);
  const [ticketMaxPage, setTicketMaxPage] = useState();
  const [merchMaxPage, setMerchMaXPage] = useState();
  const [coinMaxPage, setCoinMaXPage] = useState();
  const [checkBtnState, setCheckBtnState] = useState(false);
  const [prevBtn, setPrevBtn] = useState(false);
  const [nextBtn, setNextBtn] = useState('1');
  const pageSize = 100;
  const userDetails = getUserDetails();

  useEffect(() => {
    getTicketWinnerCountDetails();
    getSilverCoinWinners(coinApiPage);
    getTicketDataList(ticketsApiPage);
    getMerchdataList(merchApiPage);
    // setNumberOfRecordsShow(window.innerWidth < 768 ? 5 : 10);
    checkTableActivePrevBtn();
    checkTableActiveNextBtn();
  }, []);

  /**
   * @method getTicketWinnerCountDetails
   * @description get ticket count details
   */
  const getTicketWinnerCountDetails = async () => {
    try {
      const response = await getTicketWinnerCount();
      if (response.status === 200) {
        let data = response && response.data && response.data.Data;
        setTotalTicketDetails(data);
      }
    } catch (error) {
      console.error('Error updating profile name:', error);
      // Handle the error as needed
    }
  };

  /**
   * @method getMerchdataList
   * @description get all merch win user list
   */
  const getMerchdataList = async (page) => {
    let requestData = {
      PageNo: merchApiPage,
      PageSize: pageSize,
    };
    console.log('submerch', requestData);
    try {
      toggleLoader();
      const response = await getMerchList(requestData);
      if (response.status === 200) {
        let data = response && response.data && response.data.Data;
        setTotalMerchDetails(data.TotalRow);
        let winnerList = data.WinnerList;
        if (winnerList.length === 0) {
          setNextBtn(false);
          return;
        }
        // setMerchData(data)
        const mergelistPage = merchList.length + winnerList.length;
        const tempMaxPage = Math.floor(mergelistPage / numberOfRecordsShow);
        setMerchMaXPage(tempMaxPage);
        let list = [...merchList, ...winnerList];
        setMerchList(list);
        checkBtnDisplay(list);
        setMerchApiPage(merchApiPage + 1);
      }
    } catch (error) {
      console.error('Error updating profile name:', error);
      // Handle the error as needed
    }
    setTimeout(() => {
      toggleLoader();
    }, 500);
  };

  /**
   * @method getTicketDataList
   * @description get all ticket win user list
   */
  const getTicketDataList = async (page) => {
    let requestData = {
      PageNo: ticketsApiPage,
      PageSize: pageSize,
    };
    try {
      toggleLoader();
      const response = await getWinTicketList(requestData);
      if (response.status === 200) {
        let data = response && response.data && response.data.Data;
        let winnerList = data.WinnerList;
        // setTicketData(data)
        if (winnerList.length === 0) {
          setNextBtn(false);
          return;
        }
        const mergelistPage = ticketList.length + winnerList.length;
        const tempMaxPage = Math.floor(mergelistPage / numberOfRecordsShow);
        setTicketMaxPage(tempMaxPage);
        let list = [...ticketList, ...winnerList];
        console.log('sub1', tempMaxPage);
        setTicketList(list);
        checkBtnDisplay(list);
        setTicketApiPage(ticketsApiPage + 1);
      }
    } catch (error) {
      console.error('Error updating profile name:', error);
      // Handle the error as needed
    }
    setTimeout(() => {
      toggleLoader();
    }, 500);
  };

  /**
   * @method getSilverCoinWinners
   * @description get all winner list of silver coin
   */
  const getSilverCoinWinners = async (page) => {
    let requestData = {
      PageNo: coinApiPage,
      PageSize: pageSize,
    };
    try {
      toggleLoader();
      const response = await getSilverCoinList(requestData);
      if (response.status === 200) {
        let data = response && response.data && response.data.Data;
        let winnerList = data.WinnerList;
        setTotalCoinDetails(data.TotalRow);
        if (winnerList.length === 0) {
          setNextBtn(false);
          return;
        }
        const mergelistPage = silverCoinList.length + winnerList.length;
        const tempMaxPage = Math.floor(mergelistPage / numberOfRecordsShow);
        setCoinMaXPage(tempMaxPage);
        let list = [...silverCoinList, ...winnerList];
        setCoinList(list);
        checkBtnDisplay(list);
        setCoinApiPage(coinApiPage + 1);
      }
    } catch (error) {
      console.error('Error updating profile name:', error);
      // Handle the error as needed
    }
    setTimeout(() => {
      toggleLoader();
    }, 500);
  };

  const handlePrevious = () => {
    // checkTableActivePrevBtn();
    if (activetab === 'silver_coin') {
      setCoinPageNumber(coinPageNumber - 1);
      newRenderList(silverCoinList);
    } else if (activetab === 'ticket') {
      console.log('sub1', ticketPageNumber);
      setTicketPageNumber(ticketPageNumber - 1);
      newRenderList(ticketList);
    } else if (activetab === 'merch') {
      setMerchPageNumber(merchPageNumber - 1);
      newRenderList(merchList);
    }
  };

  const handleNext = () => {
    console.log('activetab', activetab);
    if (activetab === 'ticket') {
      if (ticketPageNumber + 1 < totalTicketsPages - 1) {
        checkTableActiveNextBtn();
      }
      setTicketPageNumber(ticketPageNumber + 1);
      newRenderList(ticketList);
    } else if (activetab === 'merch') {
      console.log('merch1', merchPageNumber + 1, merchTicketPages);
      if (merchPageNumber + 1 < merchTicketPages - 1) {
        checkTableActiveNextBtn();
      }
      setMerchPageNumber(merchPageNumber + 1);
      newRenderList(merchList);
    } else if (activetab === 'silver_coin') {
      if (coinPageNumber + 1 < totalCoinPages - 1) {
        checkTableActiveNextBtn();
      }
      setCoinPageNumber(coinPageNumber + 1);
      newRenderList(silverCoinList);
    }
  };

  function checkTableActivePrevBtn() {
    if (activetab === 'ticket') {
      if (ticketPageNumber <= 0) setPrevBtn(true);
      else setPrevBtn(false);
    } else if (activetab === 'merch') {
      if (merchPageNumber <= 0) setPrevBtn(true);
      else setPrevBtn(false);
    } else if (activetab === 'silver_coin') {
      if (coinPageNumber <= 0) setPrevBtn(true);
      else setPrevBtn(false);
    }
  }
  function checkTableActiveNextBtn() {
    if (activetab === 'silver_coin') {
      if (coinPageNumber === coinMaxPage - 1) {
        const oldTicketTableSize = silverCoinList.length;
        getSilverCoinWinners(coinApiPage);
        if (silverCoinList.length > oldTicketTableSize) setNextBtn(false);
        else setNextBtn(true);
      } else setNextBtn(false);
    } else if (activetab === 'ticket') {
      if (ticketPageNumber === ticketMaxPage - 1) {
        const oldTicketTableSize = ticketList.length;
        getTicketWinnerCountDetails();
        getTicketDataList(ticketsApiPage);
        if (ticketList.length > oldTicketTableSize) setNextBtn(false);
        else setNextBtn(true);
      } else setNextBtn(false);
    } else if (activetab === 'merch') {
      if (merchPageNumber === merchMaxPage - 1) {
        const oldMerchTableSize = merchList.length;
        getTicketWinnerCountDetails();
        console.log('subpage', merchApiPage);
        getMerchdataList(merchApiPage);
        if (merchList.length > oldMerchTableSize) setNextBtn(false);
        else setNextBtn(true);
      } else setNextBtn(false);
    }
  }

  function checkBtnDisplay(list) {
    if (list.length > numberOfRecordsShow) setCheckBtnState(true);
    else setCheckBtnState(false);
  }

  const newRenderList = (list) => {
    console.log('ticket page - ', ticketPageNumber, merchPageNumber);
    if (list && list.length) {
      let sublist = [],
        heading = '';
      if (activetab === 'silver_coin') {
        sublist = silverCoinList.slice(
          coinPageNumber * numberOfRecordsShow,
          coinPageNumber * numberOfRecordsShow + numberOfRecordsShow
        );
      } else if (activetab === 'ticket') {
        sublist = ticketList.slice(
          ticketPageNumber * numberOfRecordsShow,
          ticketPageNumber * numberOfRecordsShow + numberOfRecordsShow
        );
      } else if (activetab === 'merch') {
        sublist = merchList.slice(
          merchPageNumber * numberOfRecordsShow,
          merchPageNumber * numberOfRecordsShow + numberOfRecordsShow
        );
      }
      return sublist.map((el, i) => {
        if (activetab === 'silver_coin') {
          heading = coinPageNumber * numberOfRecordsShow + i + 1;
        } else if (activetab === 'ticket') {
          heading = ticketPageNumber * numberOfRecordsShow + i + 1;
        } else if (activetab === 'merch') {
          heading = merchPageNumber * numberOfRecordsShow + 1 + i;
        }
        return (
          <li
            className={
              i % 2 === 0
                ? 'list-item bg-secondary'
                : 'list-item bg-info position-realtive'
            }
            key={i}>
            <h6 className="list-special-heading">{heading}.</h6>
            <div className="list-avatar-wrapper list-special-heading">
              <img className="list-avatar" src={el.AvtarUrl} alt="avatar" />
            </div>
            <h6
              className="list-heading"
              style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
              {el.Name.length > 0 ? el.Name : '-'}
            </h6>
            <h6
              className="list-heading"
              style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
              XXXXXX{el.PhoneNumber.substring(6)}
            </h6>
          </li>
        );
      });
    }
  };

  let totalTicketsPages =
    Math.floor(totalTicketDetails / numberOfRecordsShow) +
    (totalTicketDetails % numberOfRecordsShow > 0);
  let merchTicketPages =
    Math.floor(totalMerchDetails / numberOfRecordsShow) +
    (totalMerchDetails % numberOfRecordsShow > 0);
  let totalCoinPages =
    Math.floor(totalCoinDetails / numberOfRecordsShow) +
    (totalCoinDetails % numberOfRecordsShow > 0);

  let isDisablednext = false,
    isDisabledPre = false;
  let type = 'none';
  if (activetab === 'ticket') {
    isDisablednext = totalTicketsPages - 1 === ticketPageNumber;
    isDisabledPre = ticketPageNumber === 0;
    if (totalTicketDetails > numberOfRecordsShow) {
      type = 'block';
    }
  } else if (activetab === 'merch') {
    isDisablednext = merchTicketPages - 1 === merchPageNumber;
    isDisabledPre = merchPageNumber === 0;
    if (totalMerchDetails > numberOfRecordsShow) {
      type = 'block';
    }
  } else if (activetab === 'silver_coin') {
    isDisablednext = totalCoinPages - 1 === coinPageNumber;
    isDisabledPre = coinPageNumber === 0;
    if (totalCoinDetails > numberOfRecordsShow) {
      type = 'block';
    }
  }
  return (
    <>
      <Loader />
      <div className="winner-contest">
        <section className="section section-no-strip section-table ">
          <header className="section-header ">
            <h2 className="section-title">{t.toofani_champions}</h2>
            {/* <h5 className="section-desc">
                        {totalCoinDetails > 100 && `${totalCoinDetails} ${t.champions_won_tickets}!`}
                        {totalCoinDetails > 100 && <br className="d-sm-inlinblock" />} {t.keep_collecting_coins}
                    </h5> */}
          </header>
          <div className="container-fluid">
            <div className="row justify-content-center pt-5 pb-10">
              <div className="col-8 winners-div">
                <div className="list-wrapper">
                  <div>
                    <header className="section-header p-0">
                      <h2 className="section-sub-title section-sub-title-guj">
                        {silverCoinList?.length !== 0 && (
                          <a
                            onClick={() => {
                              setActiveTab('silver_coin');
                              setTicketPageNumber(0);
                              checkBtnDisplay(silverCoinList);
                            }}
                            className="mr-2 table-link"
                            style={{
                              color:
                                activetab === 'silver_coin'
                                  ? '#00B8FF'
                                  : 'white',
                              textDecoration:
                                activetab === 'silver_coin' ? 'underline' : '',
                            }}>
                            {t.silver_coin}
                          </a>
                        )}
                        {silverCoinList?.length !== 0 &&
                        ticketList?.length !== 0
                          ? ' | '
                          : ''}
                        {ticketList?.length !== 0 && (
                          <a
                            onClick={() => {
                              setActiveTab('ticket');
                              setTicketPageNumber(0);
                              checkBtnDisplay(ticketList);
                            }}
                            className="mr-2 table-link"
                            style={{
                              color:
                                activetab === 'ticket' ? '#00B8FF' : 'white',
                              textDecoration:
                                activetab === 'ticket' ? 'underline' : '',
                            }}>
                            {t.tickets}
                          </a>
                        )}
                        {/* when both tickets and merch are vailable */}
                        {ticketList?.length !== 0 && merchList?.length !== 0
                          ? ' | '
                          : ''}

                        {merchList?.length !== 0 && (
                          <a
                            onClick={() => {
                              setActiveTab('merch');
                              setMerchPageNumber(0);
                              checkBtnDisplay(merchList);
                            }}
                            className="ml-2 table-link"
                            style={{
                              color:
                                activetab === 'merch' ? '#00B8FF' : 'white',
                              textDecoration:
                                activetab === 'merch' ? 'underline' : '',
                            }}>
                            {t.merchaindise}
                          </a>
                        )}
                      </h2>
                    </header>
                    {(ticketList?.length !== 0 ||
                      merchList?.length !== 0 ||
                      silverCoinList?.length !== 0) && (
                      <ul className={`list list-contestent`}>
                        <li className="list-item bg-primary">
                          <h6 className="list-special-heading">{t.s_no}</h6>
                          <h6 className="list-special-heading">{t.avatar}</h6>
                          <h6 className="list-heading">{t.name}</h6>
                          <h6
                            className="list-heading"
                            style={{
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                            }}>
                            {t.phone_number}
                          </h6>
                        </li>
                        {activetab === 'silver_coin' &&
                          newRenderList(silverCoinList)}
                        {activetab === 'ticket' && newRenderList(ticketList)}
                        {activetab === 'merch' && newRenderList(merchList)}
                        <div className="d-flex justify-content-center">
                          <button
                            className={`btn btn-xs btn-primary btn-prev list-btns`}
                            disabled={isDisabledPre}
                            style={{ display: type }}
                            onClick={() => handlePrevious()}>
                            {t.prev}
                          </button>
                          <button
                            className={`btn btn-xs btn-primary mx-4 btn-next list-btns`}
                            disabled={isDisablednext}
                            style={{ display: type }}
                            onClick={() => handleNext()}>
                            {t.next}
                          </button>
                        </div>
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default NewWinnerTable;
