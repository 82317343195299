import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../components/Home";
import StyleGuides from "../components/StyleGuide";
import Contest from "../components/Contest";
import BelievoMeter from "../components/BelievoMeter";
import { getUserDetails } from "../utils/helper";
import Page404 from "../components/404";
import Popups from "../components/model";
import Capture from "../components/Capture";
import { isCampaignEnded } from '../utils/helper'
import ActivityOver from '../components/ActivityOver';
import WinnerList from '../components/Winnerlist';


export default function Router(props) {

  let userDetails = getUserDetails();
  const { t } = props;
  const commonProps = {
    t: t,
    userDetails: userDetails,
  };

  return (
    <>
      <Suspense fallback={<div>{"...loading"}</div>}>
        {isCampaignEnded() ?
          <Routes>
            <Route path="/" exact element={<ActivityOver {...commonProps} />} />
            <Route
              path="/winnerList"
              exact
              element={<WinnerList {...commonProps} />}
            />
            <Route path="*" element={<Page404 />} />
          </Routes> :
          <Routes>
            <Route path="/" exact element={<Home {...commonProps} />} />
            <Route
              path="/style"
              exact
              element={<StyleGuides {...commonProps} />}
            />
            <Route path="/how_to_win" exact element={<Contest {...commonProps} />} />
            <Route
              path="/toofani_pulse"
              exact
              element={<BelievoMeter {...commonProps} />}
            />
            <Route
              path="/popup"
              exact
              element={<Popups {...commonProps} />}
            />
            <Route
              path="/capture"
              exact
              element={<Capture {...commonProps} />}
            />
            <Route path="*" element={<Page404 />} />
          </Routes>}
      </Suspense>
    </>
  );
}
