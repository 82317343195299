import React from 'react';

function CaptureBanner(props) {
    const { t } = props
    return (
        <section className='section tu-capture-section pb-4 sec-mob' >
            <h2 className='text-center pt-4 head-fix'>{t.toofani_cheers}</h2>
            <div className=' d-flex justify-content-around text-center pb-4 div-mob'>
                <div className=''>
                    <img className=""
                        src="/assets/images/capture-step-1.svg"
                        alt="Steps 1"
                    />
                    <h3 className='pt-4 step-fix'>{t.step1}</h3>
                    <p className="p-fix">{t.click_photo} </p>
                </div>
                <div>
                    <img className=""
                        src="/assets/images/capture-step-2.svg"
                        alt="Steps 2"
                    />
                    <h3 className='pt-4 step-fix'>{t.step2}</h3>
                    <p className="p-fix">{t.access_camera} </p>
                </div>

                <div>
                    <img className=""
                        src="/assets/images/capture_step3_2.svg"
                        alt="Steps 3"
                    />
                    <h3 className='pt-4 step-fix'>{t.step3}</h3>
                    <p
                    className="p-fix"
                        // dangerouslySetInnerHTML={{
                        //     __html: t.capture_yourself ,
                        // }}
                        >
                            <span
          dangerouslySetInnerHTML={{
            __html: t.capture_yourself,
          }}
        ></span>
        <img
        className="r-image"
        src="/assets/images/picture-frame2.png"
        alt="r"
        />
                            
                        </p>
                        {/* <div className="circle">
                            <span>r</span>
                        </div> */}
                </div>
            </div>
        </section>
    );
}

export default CaptureBanner;
