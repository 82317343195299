/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useEffect, useRef } from 'react';
import { Carousel, Col, Figure, Row } from 'react-bootstrap';
import LanguageContext from '../../utils/LanguageContext';
import translations from '../../utils/translation';
import TicketModel from '../model/TicketModel';
import MerchaindiseModel from '../model/MerchaindiseModel';
import {
  CounterFloodLight,
  MetaPixel,
  TTDPixel,
  TwitterPixel,
  currentYear,
  encryptUserId,
} from '../../utils/helper';
import { getTicketWinnerCount } from '../../actions/Auth';
import ReferFriendModel from '../model/ReferFriendModel';
import InfoModal from '../model/InfoModal';
import { handleShare } from '../../utils/helper';
import CommonModel from '../model/CommonModel';
import { getUserDetails } from '../../utils/helper';
import { ClickButtonCDP } from '../../utils/cdsHelper';
import TossNow from './TossNow';
import CommonContext from '../contextApi';
import { ARPlatformUrl } from '../../config/constant';
import ReactPlayer from 'react-player';
import { referFriendSuccess } from '../../utils/modelHelper';
import MyWallet from '../common/MyWallet';
import { Link, useNavigate } from 'react-router-dom';

function HomeCarousel() {
  const videoRef = useRef();
  const navigate = useNavigate();
  const { language } = useContext(LanguageContext);
  const { triggerActionAfterLogin, actionType, redirectname } =
    useContext(CommonContext);
  const t = translations[language];
  const [ticketModel, setTicketModel] = useState(false);
  const [merchaindiseModel, setmerchaindiseModel] = useState(false);
  const [totalTicketDetails, setTicketDetails] = useState('');
  const [openRefermodel, setOpenReferModel] = useState(false);
  const [tossNow, setTossNow] = useState(false);
  const [popup, setPopupInfo] = useState({
    title: '',
    content: '',
  }); // State to manage the pop-up properties
  const [showUniqueCodeModel, setShowUniqueCodeModel] = useState(false);
  const [activeStep, setActiveStep] = useState({ step: 1, type: '' });
  let user_details = getUserDetails();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [playVedio, setPlayVedio] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [showMywallet, setShowMywallet] = useState(false);

  console.log('redirectname', redirectname);

  useEffect(() => {
    if (actionType && actionType.isLoggedIn) {
      if (actionType.type === 'refer' || actionType.type === 'profile') {
        setmerchaindiseModel(false);
      } else if (actionType.type === 'unique_code') {
        setTicketModel(false);
        setActiveStep({ step: 1 });
        setShowUniqueCodeModel(true);
      } else if (actionType.type === 'vote_now') {
        setTicketModel(false);
      } else if (actionType.type === 'win_tickets') {
        setTicketModel(true);
      } else if (actionType.type === 'win_merch') {
        setmerchaindiseModel(true);
      } else if (actionType.type === 'capture_image') {
        navigate('/capture');
      }
    }
    if (user_details && redirectname && redirectname.show) {
      if (redirectname.url_name === 'win_tickets') {
        setTicketModel(true);
      } else if (redirectname.url_name === 'win_merch') {
        setmerchaindiseModel(true);
      } else if (redirectname.url_name === 'unique_code') {
        setActiveStep({ step: 1 });
        setShowUniqueCodeModel(true);
      }
      // else {
      //   setActiveStep({ step: 2 });
      //   setShowUniqueCodeModel(true);
      // }
    }
  }, [actionType, redirectname]);

  const unaffectedTextStyle = {
    fontSize: '32px',
    padding: '18px 72px',
    lineHeight: '2.4rem',
    '@media (max-width: 600px)': {
      fontSize: '14px',
    },
  };

  useEffect(() => {
    getTicketWinnerCountDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * @method getTicketWinnerCountDetails
   * @description get ticket count details
   */
  const getTicketWinnerCountDetails = async () => {
    try {
      const response = await getTicketWinnerCount();
      if (response.status === 200) {
        let data = response && response.data && response.data.Data;
        setTicketDetails(data);
      }
    } catch (error) {
      console.error('Error updating profile name:', error);
      // Handle the error as needed
    }
  };

  /**
   * @method referFriendSuccussModel
   * @description friend refer success
   */
  const referFriendSuccussModel = (code) => {
    setPopupInfo(referFriendSuccess(t, code));
  };

  //Show toss closePopup model
  const closePopup = () => {
    setPopupInfo({
      title: '', // Reset the title
      content: '', // Reset the content
    });
  };

  const handleTossNow = () => {
    if (user_details) {
      setTossNow(true);
    } else {
      setActiveStep({ step: 2, type: 'merch' });
      setShowUniqueCodeModel(true);
    }
  };

  const handlePlay = () => {
    console.log('Video started playing');
  };

  const handlePause = () => {
    console.log('Video paused');
  };

  const handleEnterAr = () => {
    console.log('encryptid');
    if (user_details) {
      const userId = user_details.Id;
      const encryptedUserId = userId;

      if (encryptedUserId != null) {
        const arAnchor = document.getElementById('arEnter');
        const redirectedUrl = ARPlatformUrl + `?userid=${encryptedUserId}`;

        window.open(redirectedUrl, '_blank', 'noopener,noreferrer');
      } else {
        window.open(ARPlatformUrl, '_blank', 'noopener,noreferrer');
      }
    } else {
      window.open(ARPlatformUrl, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <section className="section pt-0">
      <a
        href={void 0}
        className="btn btn-primary btn-share"
        onClick={() => {
          handleShare(
            // t.win_icic_world_cup_share_tag,
            'Grab a bottle of Thums Up® and visit now: https://bit.ly/44D7RrZ *TnC Apply',
            'Grab a bottle of Thums Up® and visit now: https://bit.ly/44D7RrZ *TnC Apply',
            `assets/images/pageshare_${language}.png`
          );
          ClickButtonCDP('Page_Share');
          MetaPixel('Share');
          MetaPixel('SharetoSocial');
          CounterFloodLight('DC-12665261/thums0/thums02p+unique');
          TTDPixel('pjwaosz');
          TwitterPixel('tw-ofua3-ofvck');
        }}>
        <img src="assets/images/share.svg" alt="share icon" />
      </a>
      <Carousel
        interval={300000}
        indicators={true}
        nextLabel=""
        prevLabel=""
        autoPlay={true}
        onSelect={() => {
          console.log('videoRef', videoRef?.current);
          if (videoRef.current) {
            if (videoRef.current && videoRef.current.getInternalPlayer) {
              const internalPlayer = videoRef.current.getInternalPlayer();
              // Check if the pause function is available before calling it
              if (typeof internalPlayer.pauseVideo === 'function') {
                internalPlayer.pauseVideo(); // Adjust the method name if needed
              } else {
                console.error(
                  'pauseVideo function is not available on the internal player.'
                );
              }
            }
          }
        }}>
        <Carousel.Item className="banner d-block w-100">
          <img
            className="banner-img d-none d-lg-block"
            src="assets/images/hero-banner/capture-banner-bg.jpg"
            alt="TU"
          />
          <img
            className="banner-img d-none d-md-block d-lg-none"
            src="assets/images/hero-banner/capture-banner-md-bg.jpg"
            alt="TU"
          />
          <img
            className="banner-img d-block d-md-none"
            src="assets/images/hero-banner/capture-banner-sm-bg.jpg"
            alt="TU"
          />
          <div className="container-fluid py-4 xy-center">
            <div className="d-flex justify-content-around capture-bg">
              <img
                className="b-1"
                src="assets/images/b-1.png"
                alt="banner-img-1"
              />
              <div
                className="banner-wrapper d-flex"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  flexDirection: 'column',
                }}>
                <h3 className="text-fix">{t.final_countdown}</h3>
                {!user_details ? (
                  <a
                    href={void 0}
                    className="btn btn-sm btn-primary take-picture unaffected-text "
                    // style={unaffectedTextStyle}
                    onClick={() => {
                      setActiveStep({ step: 2 });
                      setShowUniqueCodeModel(true);
                      triggerActionAfterLogin({
                        type: 'capture_image',
                        isLoggedIn: false,
                      });
                    }}>
                    {t.take_picture}
                  </a>
                ) : (
                  <Link
                    to={'/capture'}
                    className="btn btn-sm btn-primary take-picture unaffected-text"
                    // style={unaffectedTextStyle}
                  >
                    {t.take_picture}
                  </Link>
                )}
              </div>
              <img
                className="b-2"
                src="assets/images/b-2.svg"
                alt="banner-img-2"
              />
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item
          className="banner"
          onClick={() => {
            setTicketModel(true);
          }}>
          <img
            className="banner-img d-none d-lg-block"
            src="assets/images/hero-banner/pattern_3.png"
            alt="TU"
          />
          <img
            className="banner-img d-none d-md-block d-lg-none"
            src="assets/images/hero-banner/pattern_3_lg.png"
            alt="TU"
          />
          <img
            className="banner-img d-block d-md-none"
            src="assets/images/hero-banner/pattern_3_m.jpg"
            alt="TU"
          />
          <div className="container-fluid py-8 xy-center bn-text-center">
            <div className="banner-content">
              <div className="banner-content-body banner-1">
                <h1
                  className="section-title section-title-main-home title-main"
                  dangerouslySetInnerHTML={{
                    __html: t.banner_1_heading,
                  }}></h1>
                {/* <h3 className="section-sub-title">{t.banner_1_sub_heading}</h3> */}
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item className="banner ">
          <img
            className="banner-img d-none d-lg-block"
            src="assets/images/hero-banner/pattern_2_d.svg"
            alt="TU"
          />
          <img
            className="banner-img d-none d-md-block d-lg-none"
            src="assets/images/hero-banner/pattern_2_lg.svg"
            alt="TU"
          />
          <img
            className="banner-img d-block d-md-none"
            src="assets/images/hero-banner/pattern_2_m.svg"
            alt="TU"
          />
          <div className="container-fluid py-8 xy-center">
            <Row className="justify-content-center no-gutters how-to-win-carousel">
              <Col
                xs={12}
                md={3}
                className="text-center order-md-2 how-to-win-text">
                <h3 className="section-title section-title-howToWin">
                  {t.how_to_win}*
                </h3>
              </Col>
              <Col
                xs={6}
                md={5}
                lg={4}
                className="card-block card-block-sm order-md-1">
                <Figure className="d-block">
                  <img
                    className="card-block-img-top"
                    src="assets/images/sliver-coin-win.svg"
                    alt="Ticket"
                  />
                </Figure>
                <a
                  href={void 0}
                  className="btn btn-sm btn-sm-ticket btn-primary ticket-win"
                  onClick={() => {
                    setTicketModel(true);
                    ClickButtonCDP('HowToWin_Tickets');
                    CounterFloodLight('DC-12665261/thums0/thums01x+unique');
                    TTDPixel('fcvijdb');
                    TwitterPixel('tw-ofua3-ofvc4');
                  }}
                  dangerouslySetInnerHTML={{
                    __html: t.icic_mens_world_cup,
                  }}></a>
              </Col>

              <Col
                xs={6}
                md={4}
                lg={4}
                className="card-block card-block-sm order-md-3">
                <Figure className="d-block">
                  <img
                    className="card-block-img-top"
                    src="assets/images/cap-merch.png"
                    alt="Cap"
                  />
                </Figure>
                <a
                  href={void 0}
                  className="btn btn-sm btn-sm-merch btn-primary card-block-how-to-win-merch"
                  onClick={() => {
                    setmerchaindiseModel(true);
                    ClickButtonCDP('HowToWin_Merch');
                    CounterFloodLight('DC-12665261/thums0/thums01y+unique');
                    MetaPixel('ExclusiveMerchandise');
                    TTDPixel('nvbscid');
                    TwitterPixel('tw-ofua3-ofvc3');
                  }}>
                  {t.exclusive_merchaindise}
                </a>
              </Col>
            </Row>
          </div>
        </Carousel.Item>
        <Carousel.Item className="banner">
          <img
            className="banner-img d-none d-lg-block"
            src={'assets/images/hero-banner/pattern_2_d.svg'}
            alt="TU"
          />
          <img
            className="banner-img d-none d-md-block d-lg-none"
            src={'assets/images/hero-banner/pattern_6_lg.svg'}
            alt="TU"
          />
          <img
            className="banner-img d-block d-md-none"
            src="assets/images/hero-banner/pattern_2_m.svg"
            alt="TU"
          />
          <div className="container-fluid xy-center text-center collect-now-homee-carousel">
            <h3 className="mt-2 mb-4 section-title">
              {t.digital_victory_coins}
            </h3>
            <img
              className="img-fluid px-5 px-md-auto d-none d-md-block m-auto"
              src={`assets/images/steps_banner_${language}.svg`}
              alt={'steps'}
            />
            <img
              className="img-fluid px-5 px-md-auto d-block d-md-none m-auto"
              src={`assets/images/steps_banner_m_${language}.svg`}
              alt={'steps'}
            />
            <div className="banner-collect mt-3">
              <a
                href={void 0}
                className="btn btn-sm btn-primary"
                onClick={() => {
                  //setTicketModel(true);
                  if (user_details) {
                    setActiveStep({ step: 1 });
                    setShowUniqueCodeModel(true);
                  } else {
                    setActiveStep({ step: 2 });
                    setShowUniqueCodeModel(true);
                    triggerActionAfterLogin({
                      type: 'unique_code',
                      isLoggedIn: false,
                    });
                  }
                  MetaPixel('CollectNow');
                }}>
                {t.collect_now}
              </a>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item className="banner w-100">
          <img
            className="banner-img d-none d-lg-block"
            src={'assets/images/hero-banner/ar-banner.svg'}
            alt="TU"
          />
          <img
            className="banner-img d-none d-md-block d-lg-none"
            src={'assets/images/hero-banner/ar-banner-lg.svg'}
            alt="TU"
          />
          <img
            className="banner-img d-block d-md-none"
            src="assets/images/hero-banner/ar-banner-mob.svg"
            alt="TU"
          />
          <div className="container-fluid xy-center text-center tu-ar-banner">
            <h3 className="mt-2 mb-4 section-title text-right text-md-center py-sm-5 px-6 ar-title">
              {t.experience_in_ar}
            </h3>
            <div className="banner-collect text-right text-md-center pt-0 px-8">
              <div className="ar-cta">
                <a
                  href={void 0}
                  className="btn btn-sm btn-primary tuarenternowbtn"
                  // onClick={() => handleEnterAr()}
                >
                  {t.enter_now}
                </a>
              </div>
            </div>
          </div>
        </Carousel.Item>
        {/* you tube video directly embedded */}
        <Carousel.Item
          className="banner w-100"
          // onClick={() => setPlayVedio(!playVedio)}
        >
          {/* <div className="vdo-player"> */}
          <>
            <ReactPlayer
              ref={videoRef}
              url={
                'https://www.youtube.com/watch?v=6Yi0zTKRVr0&ab_channel=ThumsUpOfficial&wmode=transparent'
              } // Replace with your video URL
              playing={false} // Play only when on the first slide
              width=""
              height=""
              className="video-player" // Apply custum class to the ReactPlayer component
              controls={true}
              onPlay={handlePlay}
              onPause={handlePause}
            />
            </>
          {/* </div> */}
        </Carousel.Item>

        <Carousel.Item className="banner">
          <img
            className="banner-img d-none d-lg-block"
            src="assets/images/hero-banner/banner-4.png"
            alt="TU"
          />
          <img
            className="banner-img d-none d-md-block d-lg-none"
            src="assets/images/hero-banner/banner-4_lg.png"
            alt="TU"
          />
          <img
            className="banner-img d-block d-md-none"
            src="assets/images/hero-banner/banner-4_m.png"
            alt="TU"
          />

          <div className="container-fluid py-8 xy-center">
            <div className="banner-content-hotstar">
              <div className="banner-content-body banner-content-body-left">
                <h1 className="section-title section-title-hotstar section-title-hotstar-left">
                  <span className="section-title-hotstar-left-upperSpan">
                    WILL
                  </span>
                  <br />
                  <span className="section-title-hotstar-left-middleSpan">
                    INDIA
                  </span>
                  <br />
                  <span className="section-title-hotstar-left-lowerSpan">
                    WIN
                  </span>
                  <span className="section-title-hotstar-left-quesSpan">?</span>
                </h1>
              </div>

              <div className="banner-content-body banner-content-body-right">
                <h1 className="section-title section-title-hotstar section-title-hotstar-right">
                  <span className="section-title-hotstar-right-upperSpan">
                    INDIA
                  </span>
                  <br />
                  <span className="section-title-hotstar-right-middleSpan">
                    WILL
                  </span>
                  <br />
                  <span className="section-title-hotstar-right-lowerSpan">
                    WIN
                  </span>
                </h1>
              </div>
            </div>
          </div>
        </Carousel.Item>

        {/* banner with you tube video */}
        {/* <Carousel.Item
          className="banner"
          onClick={() => setPlayVedio(!playVedio)}>
            <ReactPlayer
                url={
                  'https://www.youtube.com/watch?v=6Yi0zTKRVr0&ab_channel=ThumsUpOfficial&wmode=transparent'
                } // Replace with your video URL
                controls={true}
                playing={false}
                width=""
                height=""
                className="video-player" // Apply custum class to the ReactPlayer component
              />
          {/* <>
            {playVedio ? (
              <ReactPlayer
                url={
                  'https://www.youtube.com/watch?v=6Yi0zTKRVr0&ab_channel=ThumsUpOfficial&wmode=transparent'
                } // Replace with your video URL
                controls={true}
                playing={true}
                width=""
                height=""
                className="video-player" // Apply custum class to the ReactPlayer component
              />
            ) : null}
            <img
              className="banner-img d-none d-lg-block"
              src="assets/images/hero-banner/pattern_7_d.jpg"
              alt="TU"
            />
            <img
              className="banner-img d-none d-md-block d-lg-none"
              src="assets/images/hero-banner/pattern_7_lg.jpg"
              alt="TU"
            />
            <img
              className="banner-img d-block d-md-none object"
              src="assets/images/hero-banner/pattern_7_m.jpg"
              alt="TU"
            />
            <div className="container-fluid xy-center text-center">
              <div className="scan">
                <h3 className="section-title">{t.thums_up_uthao}</h3>
                <h3 className="section-title scan-head">{t.world_cup_jao}</h3>
                <p className="text-large">{t.scan}</p>
              </div>
            </div>
          </>
        </Carousel.Item> */}
      </Carousel>
      {ticketModel && (
        <TicketModel
          t={t}
          show={ticketModel}
          onClose={() => setTicketModel(false)}
          showUniqueCodeModel={() => setShowUniqueCodeModel(true)}
          setShowLoginModal={() => {
            setActiveStep({ step: 2, type: 'ticket' });
            setShowUniqueCodeModel(true);
          }}
        />
      )}
      {tossNow && (
        <TossNow
          tossNowEvent={tossNow}
          closeTossNow={() => setTossNow(false)}
        />
      )}
      {merchaindiseModel && (
        <MerchaindiseModel
          t={t}
          show={merchaindiseModel}
          onClose={() => {
            setmerchaindiseModel(false);
            triggerActionAfterLogin('');
          }}
          setShowLoginModal={() => {
            setActiveStep({ step: 2, type: 'merch' });
            setShowUniqueCodeModel(true);
          }}
          ReferFriendModelOpen={() => setOpenReferModel(true)}
          handleTossNow={handleTossNow}
        />
      )}
      {openRefermodel && (
        <ReferFriendModel
          show={openRefermodel}
          handleClose={() => {
            setOpenReferModel(false);
            triggerActionAfterLogin('');
          }}
          t={t}
          referFriendSuccussModel={(code) => referFriendSuccussModel(code)}
        />
      )}
      {popup.title && (
        <InfoModal
          title={popup.title} // Set the title for the pop-up
          content={popup.content} // Set the content for the pop-up
          onClose={closePopup} // Close the pop-up
          button={popup.button}
          image={popup.image}
          className={popup.className}
          topHeading={popup.topHeading}
        />
      )}
      {showUniqueCodeModel && (
        <CommonModel
          t={t}
          title={t.worldCup2023TicketKeyunique}
          header={t.find_code}
          handleShowLoginModal={() => setShowUniqueCodeModel(true)}
          showLoginModal={showUniqueCodeModel}
          handleClose={(value) => {
            console.log('event fire', value);
            if (value === 'my_wallet') {
              setShowMywallet(true);
            }
            if (activeStep.type === 'merch') {
              setmerchaindiseModel(true);
            } else if (activeStep.type === 'ticket') {
              setTicketModel(true);
            }
            setShowUniqueCodeModel(false);
            triggerActionAfterLogin('');
          }}
          active={activeStep.step}
          seperateUniqueCodeModel={true}
        />
      )}
      {
        <MyWallet
          t={t}
          setShowLoginModal={showMywallet}
          show={showMywallet}
          handleClose={() => setShowMywallet(false)}
        />
      }
    </section>
  );
}

export default HomeCarousel;
