import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles/sass/global.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from 'axios';
import { BrowserRouter as Router, useMatch } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { ToastContainer } from 'react-toastify';
import LanguageContext from './utils/LanguageContext';
import translations from './utils/translation';
import Cookies from 'js-cookie';
import { getUserDetails, setUserDetails, getClassByname } from './utils/helper';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import CustumRoutes from './router/Routes';
import { LoaderProvider } from '../src/components/loader/LoaderContext';
import ReCAPTCHA from 'react-google-recaptcha';
import ComingSoon from '../src/components/ComingSoon';
import CommonContext from './components/contextApi';
import { useLocation, useNavigate } from 'react-router-dom';
import IdleTimer from './utils/IdealTimer';
import { getUserDetailsById, logoutUser } from './actions/Auth';
import ReactGA from 'react-ga';
import { CAPCHA_SECRET } from './config/constant';
import { API } from './config/apiUrl';
import PrivacyPolicyPDF from './components/PrivacyPolicy';
import { promotionInfoModel } from './utils/modelHelper';
import InfoModal from './components/model/InfoModal';
import { isCampaignEnded } from './utils/helper';

console.log('isCampaignEnded', isCampaignEnded());

export default function App() {
  const navigate = useNavigate();
  const { hash } = useLocation();
  const location = useLocation();
  const cookieValue = Cookies.get('language');
  const currentLanguage = cookieValue ? cookieValue : 'en';
  const [language, setLanguage] = useState(currentLanguage);
  let user_details = getUserDetails();
  const [userDetails, setUserData] = useState(user_details);
  const reCaptchaRef = useRef();
  const currentPathName = useMatch('/*')?.pathname;
  const [myWalletAction, setMyWalletAction] = useState(false);
  const [arCtaAction, setArCtaAction] = useState({ show: false, cta_name: '' });
  const [actionTriggered, setActionTriggered] = useState(false);
  const [actionType, setAction] = useState('');
  const [uniqueCodeAction, setUniqueCodeSuccessAction] = useState(false);
  const [redirectname, setRedirectname] = useState({
    show: false,
    url_name: '',
    notLogin: false,
  });
  const [infoModel, setPopupInfo] = useState({
    title: '',
    content: '',
  });

  // remove all the consoles // comment if you want to see consoles
  console.log = () => { };

  const queryParams = new URLSearchParams(location.search);

  // Access the query parameters
  const site = queryParams.get('site');
  const id = queryParams.get('id');
  const popup = queryParams.get('popup');

  console.log('site', site, id, popup);

  useEffect(() => {
    // Set the className of the body element
    document.body.className = getClassByname(currentLanguage);
    // Clean up the className when the component unmounts
    return () => {
      document.body.className = '';
    };
  }, [currentLanguage]); // Empty dependency array to run this effect only once

  useEffect(() => {
    ReactGA.initialize('AW-853074600', {
      debug: true, // Enable debugging
    });

    // Track initial page view
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  // ideal timer for session expire
  useEffect(() => {
    //const SECONDS_IN_TIME = 7 * 24 * 60 * 60; // One week (7 days) in seconds
    const SECONDS_IN_TIME = 30 * 60; // 30 minutes in seconds
    const timer = new IdleTimer({
      timeout: SECONDS_IN_TIME,
      onTimeout: () => {
        console.log('onTimeout');
        localStorage.clear();
        window.location.assign('/');
      },
      onExpired: () => {
        // do something if expired on load
        console.log('onExpired');
        localStorage.clear();
        window.location.assign('/');
      },
    });
    return () => {
      timer.cleanUp();
    };
  }, []);

  // handling all the hash routing
  useEffect(() => {
    if (hash) {
      setTimeout(() => {
        const el = document.getElementById(hash?.replace('#', '')?.trim());
        el?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }, 200);
    }
  }, [hash]);

  // fetch ip data
  useEffect(() => {
    const fetchIPAddress = async () => {
      try {
        const ipResponse = await axios.get(API.getIP);
        console.log(ipResponse, 'ipAddress');
        const ipAddress = ipResponse.data.ip;
        fetchData(ipAddress);
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };
    const fetchData = async (ipAddress) => {
      //let ipUrl = 'https://pro.ip-api.com/json/103.240.99.206?key=pXmgwzTKeZ7sORl' // west bangal ip
      let ipUrl = `https://pro.ip-api.com/json/${ipAddress}?key=pXmgwzTKeZ7sORl`; // dynamic ip
      try {
        const response = await axios.get(ipUrl);
        localStorage.setItem('ipDetails', JSON.stringify(response.data));
        console.log('Response stored in localStorage:', response.data);
      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchIPAddress();
    handleARAction();
    handleWhatsUPAction();
    promotionModel();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleARAction();
    handleWhatsUPAction();
  }, [popup, id]); // eslint-disable-line react-hooks/exhaustive-deps

  // trigger action for profile open out side of the component
  const triggerAction = (value) => {
    setActionTriggered(value);
  };

  //trigger action for after login
  const triggerActionAfterLogin = (value) => {
    setAction(value);
  };

  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
  };

  const handleRedirection = () => {
    if (popup === 'profile') {
      triggerAction(true);
      setMyWalletAction(false);
    } else if (popup === 'wallet') {
      setMyWalletAction(true);
      triggerAction(false);
    } else if (popup === 'claim_ticket') {
      setArCtaAction({ show: true, cta_name: 'claim_ticket' });
    } else if (popup === 'claim_merch') {
      setArCtaAction({ show: true, cta_name: 'claim_merch' });
    }
  };

  /**
   * Fetch user data from the server and update the state with the retrieved details
   */
  const getUserData = async (id) => {
    const response = await getUserDetailsById({
      Id: id,
    });
    if (response && response.status === 200) {
      let data = response && response.data && response.data.Data;
      setUserDetails(data);
      handleRedirection();
    }
  };

  //handle ar redirection in microsite
  const handleARAction = () => {
    if (site === 'AR' && id) {
      localStorage.removeItem('user_details');
      getUserData(id);
    }
  };

  // handle whatsapp redirection
  const handleWhatsUPAction = () => {
    if (
      popup === 'win_tickets' ||
      popup === 'invite_code' ||
      popup === 'claim_ticket' ||
      popup === 'win_merch' ||
      popup === 'unique_code' ||
      popup === 'refer_a_friend' ||
      popup === 'my_wallet' ||
      popup === 'claim_merch'
    ) {
      if (userDetails) {
        setRedirectname({ show: true, url_name: popup });
        triggerAction(false);
      } else {
        setRedirectname({ show: false, url_name: '', notLogin: true });
        triggerActionAfterLogin({ type: popup, isLoggedIn: false });
      }
    } else if (popup === 'profile') {
      console.log('case2');
      if (userDetails) {
        triggerAction(true);
      } else {
        setRedirectname({ show: false, url_name: '', notLogin: true });
        triggerActionAfterLogin({ type: popup, isLoggedIn: false });
      }
    } else {
      setRedirectname({ show: false, url_name: '', notLogin: false });
    }
  };

  // for time being adding to open once a referal is completed to open coin win bcz its code is not separetee, mixed with invite model, we can scrape it once code is separated
  const promotionModel = () => {
    let t = translations[language];
    setPopupInfo(promotionInfoModel(t));
  };

  const renderPromotionModel = () => {
    return (
      <InfoModal
        title={infoModel.title} // Set the title for the pop-up
        content={infoModel.content} // Set the content for the pop-up
        className={infoModel.className}
        topHeading={infoModel.topHeading}
        button={infoModel.button}
        onClose={closePopup} // Close the pop-up
      />
    );
  };

  const closePopup = () => {
    setPopupInfo({
      title: '', // Reset the title
      content: '', // Reset the content
    });
  };

  return (
    // <div className={getClassByname(currentLanguage)}>
    <LoaderProvider>
      <LanguageContext.Provider
        value={{ t: translations, language, changeLanguage, userDetails }}>
        {isCampaignEnded() ? (
          <>
            <CustumRoutes
              t={translations[language]}
              userDetails={userDetails}
            />
            <Footer />
          </>
        ) : (
          <div>
            {currentPathName === '/comingsoon' ? (
              <ComingSoon />
            ) : currentPathName === '/privacy_policy' ? (
              <PrivacyPolicyPDF />
            ) : (
              <>
                <CommonContext.Provider
                  value={{
                    actionTriggered,
                    triggerAction,
                    actionType,
                    triggerActionAfterLogin,
                    myWalletAction,
                    arCtaAction,
                    setArCtaAction,
                    redirectname,
                    setRedirectname,
                  }}>
                  <Header />
                  <div>
                    <CustumRoutes
                      t={translations[language]}
                      userDetails={userDetails}
                    />
                    {infoModel && infoModel.title && renderPromotionModel()}
                  </div>
                  <Footer />
                  <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                  />
                </CommonContext.Provider>
                {/* <ReCAPTCHA
                theme="dark"
                size="invisible"
                ref={reCaptchaRef}
                sitekey={CAPCHA_SECRET}
              />  */}
              </>
            )}
          </div>
        )}
      </LanguageContext.Provider>
    </LoaderProvider>
    // </div>
  );
}
