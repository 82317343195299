import axios from 'axios';
import Cookies from 'js-cookie';

//dev url 
// const baseURL = process.env.REACT_APP_DEV_URL

// production url 
const baseURL = process.env.REACT_APP_PROD_URL

// local server
// const baseURL = process.env.REACT_APP_LOCAL_URL
// const baseURL = 'https://sageerpbackend-staging.moreyeahs.in/'
//const baseURL = 'https://54bb-103-15-67-130.ngrok-free.app'

//staging server
//const baseURL = process.env.REACT_APP_STAGING_URL



if (baseURL === process.env.REACT_APP_PROD_URL) {
    localStorage.setItem('server', 'PROD')
    localStorage.removeItem('user_details_dev')
} else {
    localStorage.setItem('server', 'DEV')
    localStorage.removeItem('user_details_prod')
}

const axiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 50000,
});

axiosInstance.interceptors.request.use(
    (config) => {
        // Intercept the request before it is sent
        let token = localStorage.getItem('token')
        //const token = Cookies.get('token');
        if (token) {
            config.headers['Authorization'] = `bearer ${token}`;
        }

        // Check if the request method is PUT, PATCH, or DELETE
        if (['PUT', 'PATCH', 'DELETE'].includes(config.method.toUpperCase())) {
            console.error(`Request method ${config.method} is not allowed.`);
            return Promise.reject(new Error(`Request method ${config.method} is not allowed.`));
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Export the axios instance
export default axiosInstance;
